.App {
  text-align: center;
  background-image: url("./flying_dress_photo.jpeg");
  background-size: cover;
  min-height: 100vh;
}

.App-header {
  background: rgba(255, 255, 255, 0.25);
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.25em;
  color: white;
}
